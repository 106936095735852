<template>
  <section class="section">
    <b-steps :has-navigation="false" v-model="stepIndex">
      <b-step-item icon="fas fa-user">
        <h1 class="title">Forgot your password?</h1>
        <p class="subtitle">
          Enter the email associated with your account. We'll send you a code to
          confirm your identity.
        </p>
        <form class="container" @submit.prevent="onEmailSubmit">
          <div class="field">
            <p class="control has-icons-left">
              <input
                v-model="email"
                class="input"
                placeholder="Email"
                type="email"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div v-if="isError">
            <b-notification
              type="is-danger is-light"
              aria-close-label="Close notification"
              role="alert"
            >
              {{ errorMessage }}
            </b-notification>
          </div>
          <div class="container">
            <button class="button is-primary is-xl my-3" type="submit">
              Request code
            </button>
          </div>
        </form>
      </b-step-item>
      <b-step-item icon="fas fa-lock">
        <h1 class="title">Choose a new password</h1>
        <p class="subtitle">
          You'll also need the code we emailed you to confirm your identity.
        </p>
        <form class="container" @submit.prevent="onCodeSubmit">
          <div class="field">
            <p class="control">
              <input
                v-model="confirmationCode"
                class="input"
                placeholder="Confirmation code"
                type="numeric"
              />
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input
                v-model="newPassword"
                class="input"
                placeholder="New password"
                type="password"
                autocomplete="false"
              />
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input
                v-model="confirmNewPassword"
                class="input"
                placeholder="Confirm new password"
                type="password"
                autocomplete="false"
              />
            </p>
          </div>
          <p>
            Didn't recieve the code?
            <a @click="onEmailSubmit">Request another</a>
          </p>
          <div v-if="isError">
            <b-notification
              type="is-danger is-light"
              aria-close-label="Close notification"
              role="alert"
            >
              {{ errorMessage }}
            </b-notification>
          </div>
          <div class="container">
            <button class="button is-primary is-xl my-3" type="submit">
              Update password
            </button>
          </div>
        </form>
      </b-step-item>
      <b-step-item icon="fas fa-check">
        <h1 class="title">Password updated successfully</h1>
        <p class="subtitle">
          You should now be able to log in via the
          <router-link to="/login">login page</router-link>.
        </p>
      </b-step-item>
    </b-steps>
  </section>
</template>

<script>
import { Auth } from "../../node_modules/aws-amplify";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      confirmationCode: null,
      newPassword: null,
      confirmNewPassword: null,
      stepIndex: 0,
      isError: false,
      errorMessage: null,
    };
  },
  methods: {
    onEmailSubmit() {
      Auth.forgotPassword(this.email)
        .then(() => {
          this.stepIndex = 1;
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.isError = true;
        });
    },
    onCodeSubmit() {
      if (this.validatePassword()) {
        Auth.forgotPasswordSubmit(
          this.email,
          this.confirmationCode,
          this.newPassword
        )
          .then(() => {
            this.stepIndex = 2;
          })
          .catch((err) => {
            this.errorMessage = err.message;
            this.isError = true;
          });
      }
    },
    validatePassword() {
      const isValid = this.newPassword == this.confirmNewPassword;
      if (!isValid) {
        this.errorMessage = "Those passwords don't match. Try again";
        this.isError = true;
      }
      return isValid;
    },
  },
};
</script>

<style></style>
