<template>
  <div class="page">
    <div class="container">
      <section>
        <h1 class="title is-4">Settings</h1>

        <div class="tile is-ancestor">

          <div class="tile is-parent"> 
            <Tile>
              <b-field horizontal label="Name">
                <b-input placeholder="Name"
                      v-model="email"
                      icon="user"
                      icon-right="edit"
                      icon-right-clickable
                      readonly
                      >
                  </b-input>
              </b-field>
              <b-field horizontal label="Last Name">
                <b-input placeholder="Name"
                      v-model="email"
                      icon="user"
                      icon-right="edit"
                      icon-right-clickable
                      readonly
                      >
                  </b-input>
              </b-field>
            </Tile>
              
          </div>

          <div class="tile is-parent">
            <Tile />
          </div>

        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Tile from '@/components/Tile.vue'

export default {
    props:['icon'],
    components:{Tile},
    data() {
        return {
            radioButton: ''
        }
    },
    computed:{
      image(){
        return require('@/assets/'+ this.icon +'.png')
      }
    }
}

</script>


<style scoped>
.page {
  background: linear-gradient(
    to bottom,
    white 0%,
    white 10%,
    rgb(245, 247, 250) 10%,
    rgb(245, 247, 250) 100%
  );
}
section {
  margin-top: 5rem;
}
.card .card-header {
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
}
</style>