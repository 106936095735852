 <template>
  <div class="card tile is-child">
  <header class="card-header">
    <p class="card-header-title">Profile</p>
  </header>

  <div class="card-content">
    <div class="content">
       <slot></slot>
    </div>
  </div>

  <footer class="card-footer">
  </footer>
</div>      
</template>

<script>
export default {
    props:['icon'],
    data() {
        return {
            radioButton: ''
        }
    },
    computed:{
      image(){
        return require('@/assets/'+ this.icon +'.png')
      }
    }
}
</script>

<style scoped>
.card .card-header {
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
}
</style>