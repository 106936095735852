<template>
  <section class="section">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="container">
          <form class="container" @submit.prevent="onSubmit">
            <h1 class="title">Welcome back</h1>
            <p class="subtitle">
              Not got an account?
              <router-link
                :to="{
                  name: 'Signup',
                  params: {
                    isUnconfirmed: isUnconfirmed,
                    email: email,
                  },
                }"
                >Sign up</router-link
              >
            </p>
            <div class="field">
              <p class="control has-icons-left">
                <input
                  v-model="email"
                  class="input"
                  placeholder="Email"
                  type="email"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control has-icons-left">
                <input
                  v-model="password"
                  class="input"
                  placeholder="Password"
                  type="password"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <router-link to="/password_reset"
              >Forgot your password?</router-link
            >
            <div v-if="isError">
              <b-notification
                type="is-danger is-light"
                aria-close-label="Close notification"
                role="alert"
              >
                {{ errorMessage }}
              </b-notification>
            </div>
            <div class="container">
              <button class="button is-primary is-xl my-3" type="submit">
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="column"></div>
    </div>
    <b-loading v-model="isLoading"></b-loading>
  </section>
</template>

<script>
import { Auth } from "../../node_modules/aws-amplify";
export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      isError: false,
      isUnconfirmed: false,
      isLoading: false,
      errorMessage: null,
    };
  },
  methods: {
    async onSubmit() {
      
      try {
        this.setLoading(true);
        const user = await Auth.signIn(this.email, this.password);
        if (user.signInUserSession.idToken.payload.role != 'admin') { //this needs to go in other app, leave shell for unit unregistered
          // await Auth.signOut();
          // this.$store.commit("SET_AUTH", false);
          // throw new Error("Unauthorised user")
        }
        this.$store.commit("SET_AUTH", true);
        this.$store.commit(
          "SET_TOKEN",
          user.signInUserSession.idToken.jwtToken
        );
        this.$store.commit(
          "SET_REFRESH",
          user.signInUserSession.refreshToken.token
        );
        this.$store.commit(
          "SET_EXPIRES",
          // The expiry time on the access token is in epoch seconds but must be converted to miliseconds for javascript dates
          user.signInUserSession.accessToken.payload.exp * 1000
        );
        this.$store.commit(
          "SET_UID",
           JSON.parse(user.signInUserSession.idToken.payload.unit_id)
        );
        this.$router.push({ name: "Home" });
      } catch (e) {
        console.error(e);
        this.handleErrror(e);
      }
      this.setLoading(false);
    },
    handleErrror(e) {
      if (e.code == "UserNotConfirmedException") {
        // This occurs when they abort sign in before confirming their account
        this.isUnconfirmed = true;
        this.$router.push({
          name: "Signup",
          params: {
            isUnconfirmed: this.isUnconfirmed,
            email: this.email,
          },
        });
      } else {
        this.errorMessage = e.message;
      }
      this.isError = true;
      this.isLoading = false;
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },
  computed: {},
  created() {
    this.$store.commit("SET_AUTH", false);
  }
};
</script>
