<template>
  <section class="section">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="container">
          <form class="container" @submit.prevent="onSubmit">
            <h1 class="title">Add Device</h1>
            <div class="field">
              <p class="control has-icons-left">
                <input
                  v-model="unitID"
                  class="input"
                  placeholder="Device ID"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-fingerprint"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control has-icons-left">
                <input
                  v-model="unitName"
                  class="input"
                  placeholder="Device Name"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-certificate"></i>
                </span>
              </p>
            </div>
            <div v-if="isError">
              <b-notification
                type="is-danger is-light"
                aria-close-label="Close notification"
                role="alert"
              >
                {{ errorMessage }}
              </b-notification>
            </div>
            <div class="container">
              <!-- <button class="button is-primary is-xl my-3" type="submit" :loading="isLoading">
                Add Device
              </button> -->
              <b-button class="my-3" native-type="submit" type="is-primary" :loading="isLoading">Add Device</b-button>
            </div>
          </form>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </section>
</template>

<script>
import { getToken } from "../session";
import axios from 'axios';

export default {
  name: "Login",
  data() {
    return {
      unitID: null,
      unitName: null,
      data: null,
      isSuccess: false,
      isError: false,
      isUnconfirmed: false,
      isLoading: false,
      errorMessage: null,
    };
  },
  methods: {
    async onSubmit() {
       try {
        this.setLoading(true);
        axios
        .post(process.env.VUE_APP_API_BASE + 'ID/settings/user', {
          Id: this.unitID,
          Name: this.unitName
        },{
          headers: {
            'X-Amz-Security-Token':  await getToken()//this.$store.getters.getToken
          }
        })
        .then(response => (this.data = response.data))
        .then(()=>{
          this.success();
          this.unitID = null;
          this.unitName = null;
        })
        .catch((error) => {
          if(error) {
            this.errorMessage = 'This device ID does not exist';
            this.isError=true;
          }
        })
        .then(this.setLoading(false))

      } catch (e) {
        console.error('Error: ', e);
        this.isError=true;
        this.errorMessage = 'This device Id does not exist';
      }
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    success() {
        this.$buefy.snackbar.open({
            message: 'Unit Added Succesfully',
            position: 'is-top',
            actionText: 'Go To Dashboard',
            indefinite: true,
            onAction: () => {
              this.$router.push({ path: '/' })
            }
        })
    },
  }
}
</script>
